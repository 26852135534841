import React from 'react';
import { ATTENDANCE } from '@brainbirds/shared-core/dist/constants/participant';
import isEmpty from 'lodash/isEmpty';
import AttendanceCheckbox from './components/AttendanceCheckbox';
import GenerateCertificateButton from './components/GenerateCertificateButton';

import styles from './ParticipantsList.module.scss';

// Disable the linting for the next line because there are no types available for react-tables
// and therefore the parameters that are passed are unknown
// eslint-disable @typescript-eslint/explicit-module-boundary-types
export const generateColumnsFn = (): any => [
  {
    Header: 'ID',
    id: 'id',
    accessor: 'id',
    width: 40,
  },
  {
    Header: 'First Name',
    id: 'firstName',
    accessor: 'user.contact.firstName',
    width: 80,
  },
  {
    Header: 'Last Name',
    id: 'lastName',
    accessor: 'user.contact.lastName',
    width: 80,
  },
  {
    Header: 'Company',
    id: 'company',
    accessor: 'orderItem.order.customerCompany',
    width: 100,
  },
  {
    Header: 'Organisation',
    id: 'organisation',
    accessor: 'orderItem.order.organisation.name',
    width: 100,
  },
  {
    Header: 'Attendance',
    id: 'attendance',
    accessor: 'attendance',
    width: 60,
    Cell: (data: any) => (
      <div className={styles.nameCell}>
        <AttendanceCheckbox {...data.row.original} />
        {data.row.original.attendance === ATTENDANCE.CONFIRMED}
      </div>
    ),
  },
  {
    Header: 'Certificate',
    id: 'certificate',
    width: 80,
    Cell: (data: any) => (
      <div className={styles.nameCell}>
        {isEmpty(data.row.original.mediaFile) ? (
          <GenerateCertificateButton participantId={data.row.original.id} />
        ) : (
          <p>Certificate created</p>
        )}
      </div>
    ),
  },
];
