import React from 'react';
import { enums, Event, Organisation } from '@brainbirds/shared-core';
import styles from '~/components/Events/Details/Details.module.scss';
import { store } from '~/store';


interface IPropTypes {
  event: Event;
}

const EventDesc: React.FC<IPropTypes> = ({ event }) => {
  const { organisations }: { organisations: Organisation[] } = store.getState();
  const mappedOrganisations = event.bundleVariant?.bundleVersion?.enabledForOrganisations
    .map(
      organisationId => organisations.find(({ id }) => id === organisationId)?.name
    );
  return (
    <div className={styles.contentParagraph}>
      <p>Product:</p>
      <strong>{event.displayAs || event.bundleVariant?.bundleVersion?.attributes.name}</strong>

      <p>Deal-ID:</p>
      <strong>{event.pipedrive?.dealId || '-'}</strong>

      <p>Client:</p>
      <strong>{event.clientContact?.address?.company || '-'}</strong>

      <p>Status:</p>
      <strong style={{ color: event.status === enums.event.status.CANCELLED ? 'red' : 'inherit' }}>{event.status}</strong>

      <p>Event Type:</p>
      <strong>{event.bundleVariant?.type}</strong>

      <p>Language:</p>
      <strong>{event.language?.toUpperCase()}</strong>

      <p>Location:</p>
      <strong>{event.eventLocation?.name || '-'}</strong>

      <p>Organization:</p>
      <strong>{mappedOrganisations?.join(', ') || '-'}</strong>

      <p>Zielsetzung:</p>
      <strong>{event.pipedrive?.objective || '-'}</strong>

      <p>Ausgangslage:</p>
      <strong>{event.pipedrive?.initialPosition || '-'}</strong>

      <p>Vorschlag zur Vorgehensweise:</p>
      <strong>{event.pipedrive?.proposalForHowToProceed || '-'}</strong>
    </div>
  );
};

export default EventDesc;
