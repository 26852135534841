import React from 'react';
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import Layout from '~/components/Layout';
import { EventParticipants, EventDetails, EventsDashboard } from '~/components/Events';

const Events: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Layout>
      <Switch>
        <Route exact path={path}>
          <EventsDashboard />
        </Route>
        <Route exact path={`${path}/:slug`}>
          <EventDetails />
        </Route>
        <Route exact path={`${path}/:slug/participants`}>
          <EventParticipants />
        </Route>
      </Switch>
    </Layout>
  );
};

export default Events;
