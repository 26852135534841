import { Organisation } from '@brainbirds/shared-core';
import { initialState } from '~/store/reducers/initialState';
import IEventResponse from '~/interfaces/event';

export const GET_ORGANISATIONS = 'organisations/get';

interface IAction {
  payload?: IEventResponse[];
  type: string;
}

export const organisationsReducer = (state: Organisation[] = initialState.organisations, action: IAction): any => {
  switch (action.type) {
    case GET_ORGANISATIONS:
      return action.payload;
    default:
      return state;
  }
};
