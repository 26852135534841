import React from 'react';
import type Contact from '@brainbirds/shared-core/dist/types/Contact';

import styles from '../Details.module.scss';

interface IPropTypes {
  trainerContact: Contact;
}

const TrainerContact: React.FC<IPropTypes> = ({ trainerContact }) =>
  <span className={styles.contentParagraph}>
    <p>Firstname:</p>
    <strong>{trainerContact?.firstName || '-'}</strong>
    <p>Lastname:</p>
    <strong>{trainerContact?.lastName || '-'}</strong>
    <p>E-Mail:</p>
    <strong>{trainerContact?.email || '-'}</strong>
    <p>Telephone:</p>
    <strong>{trainerContact?.telephone?.[0] || '-'}</strong>
    <p>Company:</p>
    <strong>{trainerContact.address?.company || '-'}</strong>
    <p>Additional Info:</p>
    <strong>{trainerContact.address?.additionalInfo || '-'}</strong>
    <p>Street:</p>
    <strong>{trainerContact.address?.street || '-'}</strong>
    <p>Number:</p>
    <strong>{trainerContact.address?.houseNumber || '-'}</strong>
    <p>Zip-Code:</p>
    <strong>{trainerContact.address?.postalCode || '-'}</strong>
    <p>City:</p>
    <strong>{trainerContact.address?.city || '-'}</strong>
    <p>Country:</p>
    <strong>{trainerContact.address?.country?.name || '-'}</strong>
  </span>;

export default TrainerContact;
