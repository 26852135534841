import React from 'react';
import classNames from 'classnames/bind';

import Nav from '~/components/Nav';
import styles from './Layout.module.scss';
import './reset.scss';

const cx = classNames.bind(styles);

interface IPropTypes {
  children: React.ReactElement;
}

const Layout: React.FC<IPropTypes> = ({ children }) => (
  <>
    <Nav />
    <div className={cx({ layout: true, rootclass: true })} id='layout'>
      <div className='container'>{children}</div>
    </div>
  </>
);

export default Layout;
