import jwtDecode from 'jwt-decode';

interface Token {
  exp: number;
  id: number;
}

export const isValid = (token = localStorage.getItem('token')) => {
  if (token) {
    const decodedToken = jwtDecode<Token>(token);
    const sessionIsValid = decodedToken.exp * 1000 - Date.now() >= 0;
    return sessionIsValid;
  }
  return false;
};
