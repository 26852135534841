import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { getUserSelf } from '~/store/actions/userActions';

interface IPropTypes extends RouteProps {
  component: any;
  title: string;
}

const PrivateRoute: React.FC<IPropTypes> = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const isLoggedIn = localStorage.getItem('accessToken');

  useEffect(() => {
    dispatch(getUserSelf());
  }, [dispatch]);

  return (
    <Route
      {...rest}
      render={props => {
        if (!isLoggedIn) {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }

        return (
          <>
            <title>{`Brainbirds Studio | ${rest.title}`}</title>
            <Component {...props} />
          </>
        );
      }}
    />
  );
};

export default PrivateRoute;
