import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Table, Spinner } from '@brainbirds/frontend-library';
import isEmpty from 'lodash/isEmpty';
import { getEventsFromTrainer } from '~/store/actions/eventActions';
import { generateColumnsFn } from '~/components/Events/Dashboard/generateData';
import { RootState } from '~/store';

import { ReduxDispatch } from '~/store/interfaces';

const EventsDashboard: React.FC = () => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch<ReduxDispatch>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const events = useSelector((state: RootState) => state.events.eventList);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getEventsFromTrainer()).then(() => setIsLoading(false));
  }, [dispatch]);

  return (
    <>
      <h1>Events</h1>
      {isLoading ? (
        <Spinner />
      ) : !isEmpty(events) ? (
        <Table data={events} columns={generateColumnsFn(url)} showFilters tableKey='events' />
      ) : (
        <p>You have no Events assigned to your Account.</p>
      )}
    </>
  );
};

export default EventsDashboard;
