import React from 'react';
import { useSelector } from 'react-redux';
import Layout from '~/components/Layout';
import { RootState } from '~/store';

const Dashboard: React.FC = () => {
  const firstName = useSelector((state: RootState) => state.user.accountData?.contact?.firstName);

  return (
    <Layout>
      {firstName && <h1>Hallo {firstName}!</h1>}
    </Layout>
  );
};

export default Dashboard;
