// user
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const USER_LOGOUT = 'USER_LOGOUT';
export const CUSTOMER_NEW_PW_STATUS = 'CUSTOMER_NEW_PW_STATUS';

// events
export const UPDATE_EVENT_LIST = 'UPDATE_EVENT_LIST';
export const UPDATE_SINGLE_EVENT = 'UPDATE_SINGLE_EVENT';

// participants list
export const UPDATE_PARTICIPANTS_LIST = 'UPDATE_PARTICIPANTS_LIST';
export const UPDATE_PARTICIPANTS_LIST_WITH_UPDATED_PARTICIPANT = 'UPDATE_PARTICIPANTS_LIST_WITH_UPDATED_PARTICIPANT';
export const MEDIA_FILE_GENERATED_SUCCESSFULLY = 'MEDIA_FILE_GENERATED_SUCCESSFULLY';
export const GENERATE_MEDIA_FILE = 'GENERATE_MEDIA_FILE';
