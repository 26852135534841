import { combineReducers } from 'redux';
import { userReducer } from './user';
import { eventReducer } from './events';
import { participantsReducer } from './participantsList';
import { organisationsReducer } from './organisations';

const rootReducer = combineReducers({
  user: userReducer,
  events: eventReducer,
  participants: participantsReducer,
  organisations: organisationsReducer,
});

export default rootReducer;
