import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button } from '@brainbirds/frontend-library';
import { useDispatch } from 'react-redux';
import { sendPwForgotMail } from '~/store/actions/userActions';

import styles from '~/components/Auth/Auth.module.scss';

const PasswordReset: React.FC = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [mailSentMsg, setMailSentMsg] = useState<string>('');

  return (
    <>
      <div className={styles.header}>
        <h1>Forgot Password?</h1>
        <h4>Please input your E-Mail</h4>
      </div>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required('E-Mail is required'),
        })}
        onSubmit={ async ({ email }, { setStatus, setSubmitting }) => {
          setStatus();
          try {
            await dispatch(sendPwForgotMail(email));

            setMailSentMsg('Mail sent');
            setTimeout(() => push('/'), 2000);
          } catch (error: any) {
            setSubmitting(false);
            setStatus(error.toString());}
        }}
        render={({ status, isSubmitting }) => (
          <Form>
            <div className={styles.inputWrap}>
              <label htmlFor='email'>E-Mail</label>
              <Field name='email' type='text' className={styles.inputFormControl} />
              <ErrorMessage name='email' component='div' className={styles.inputErr} />
            </div>

            <div className={styles.inputWrap}>
              <Button color='pink' type='submit' disabled={isSubmitting}>Reset Password</Button>
              <Button color='pink' variant='outlined' type='button' spacingTop='md' href='/login'>
                  Back to Login
              </Button>
            </div>
            {status && (
              <div className='alert alertError'>
                {status}
              </div>
            )}
            {mailSentMsg && (
              <div className='alert alertSuccess'>
                {mailSentMsg}
              </div>
            )}
          </Form>
        )}
      />
    </>
  );
};

export default PasswordReset;
