import { initialState } from '~/store/reducers/initialState';
import { UPDATE_EVENT_LIST, UPDATE_SINGLE_EVENT } from '~/constants/action-types';
import IEventResponse from '~/interfaces/event';

interface IAction {
  payload?: IEventResponse[];
  type: string;
}

export const eventReducer = (state: any = initialState.events, action: IAction): any => {
  switch (action.type) {
    case UPDATE_EVENT_LIST:
      return { ...state, eventList: action.payload };
    case UPDATE_SINGLE_EVENT:
      return { ...state, singleEvent: action.payload };

    default:
      return state;
  }
};
