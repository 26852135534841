import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory, Link } from 'react-router-dom';
import { Icon } from '@brainbirds/frontend-library';
import { logout } from '~/store/actions/userActions';

import styles from './Nav.module.scss';

const SideNav: React.FC = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    push('/login');
  };

  return (
    <div className={styles.sideNavContainer} id='sidenav'>
      <div className={styles.sideNav}>
        <div className={styles.logoWrap}>
          <Link to='/dashboard'>
            <Icon src='/logo-bb-studio.svg' />
          </Link>
        </div>
        <div className={styles.mainSideNavContent}>
          <NavLink to='/dashboard' activeClassName={styles.active} data-testid='sidenav-dashboard'>
            <span>Dashboard</span>
          </NavLink>
          <NavLink to='/events' activeClassName={styles.active} data-testid='sidenav-events'>
            <span>Events</span>
          </NavLink>
          <NavLink to='/' onClick={() => handleLogout()} data-testid='sidenav-logout'>
            <span>Logout</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
