import { userTypes as userTypesEnums } from '@brainbirds/shared-core/dist/enums/user';
import { generateQuery } from '@brainbirds/shared-core/dist/gql';
import { User } from '@brainbirds/shared-core';
import { gql } from '@apollo/client';
import axios, { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { CUSTOMER_NEW_PW_STATUS, UPDATE_CURRENT_USER, USER_LOGOUT } from '~/constants/action-types';
import { ThunkResult } from '~/store/interfaces';
import apolloClient from '~/services/apolloClient';

export const getUserSelf = () => async (dispatch: Dispatch): Promise<void> => {
  const query = generateQuery({
    userSelf: [{
      fragment: 'contact',
    }],
    columns: ['id', 'email'],
  }).toString();
  const { data } = await apolloClient.query<{ userSelf: User }>({
    query: gql`${query}`,
    fetchPolicy: 'network-only'
  });
  dispatch({ type: UPDATE_CURRENT_USER, payload: data.userSelf });
};

interface IUserInput {
  email: string;
  password: string;
}

interface ILoginResponse {
  organisations: {
    id: string;
    name: string;
    code: string;
    piviliges: {
      id: number;
      key: string;
      value: string;
    }[];
    userTypes: {
      id: number;
      name: string;
    }[];
  }[];
  accessToken: string;
  refreshToken: string;
}

export const login = (userInput: IUserInput): ThunkResult<Promise<void>> =>
  async () => {
    try {
      const { data } = await axios.post<ILoginResponse>(`${process.env.REACT_APP_API_ENDPOINT}/users/login`, userInput);

      const hasTrainerRole = data.organisations.find((org) => {
        const { userTypes } = org;
        const isTrainer = userTypes.find((userType) => userType.name === userTypesEnums.TRAINER || userType.name === userTypesEnums.TRAINER_LEAD);
        return isTrainer;
      });

      if (!hasTrainerRole) {
        throw new Error('You are not a trainer');
      }
      localStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('refreshToken', data.refreshToken);
    } catch (error: any) {
      if (error instanceof AxiosError && error?.response?.data.message) {
        throw new Error(error.response.data.message);
      }
      throw Error(error);
    }
  };

export const sendPwForgotMail = (email: string): ThunkResult<Promise<void>> =>
  async () => {
    try {
      const callbackUrl = `${new URL(window.location.href).origin}/setpw`;
      await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/users/forgot-password`, { email, callbackUrl });
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  };

export const createNewPassword = (newPassword: string, tempToken: string): ThunkResult<Promise<void>> =>
  async (dispatch)=> {
    try {
      await axios.patch(`${process.env.REACT_APP_API_ENDPOINT}/users/forgot-password`,
        { newPassword },
        {
          headers: {
            Authorization: `Bearer ${tempToken}`,
          },
        },
      );

      dispatch({ type: CUSTOMER_NEW_PW_STATUS, success: true });
    } catch (error: any) {
      dispatch({ type: CUSTOMER_NEW_PW_STATUS, success: false, error: error.message });
      throw new Error(error.message);
    }
  };

export const logout = (): ThunkResult =>
  (dispatch)=> {
    dispatch({ type: USER_LOGOUT });
  };
