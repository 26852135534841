import React, { useState } from 'react';
import { Button, Icon } from '@brainbirds/frontend-library';
import { event as EVENT, Event as IEvent, Organisation, EventDate } from '@brainbirds/shared-core';
import SortIcon from '@brainbirds/frontend-library/dist/static/chevron-thick.svg';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { store } from '~/store';
import IEventResponse from '~/interfaces/event';

import styles from './tableColums.module.scss';

interface IGenerateColumns {
  DropDownFilter: {
    column: {
      filterLabel: string;
      filterValue: string;
      setFilter: Function;
      preFilteredRows: any;
      id: number | string;
    };
  };
  DateRangeFilter: {
    column: {
      filterLabel: string;
      filterValue: string[];
      setFilter: Function;
    };
  };
}

export const generateColumnsFn =
  (url: string) =>
    ({ DropDownFilter, DateRangeFilter }: IGenerateColumns): any => {
      const { organisations }: { organisations: Organisation[] } = store.getState();
      return [
        {
          Header: 'Product',
          id: 'product',
          accessor: 'displayAs',
          Filter: DropDownFilter,
          filterLabel: 'Product',
          width: 150,
          Cell: (data: any) => (
            <div className={styles.nameCell}>
              <Link
                to={{
                  pathname: `${url}/${data.row.original.id}`,
                }}
              >
                <p>{!isEmpty(data.value) ? data.value : (data.row.original.bundleVariant?.bundleVersion?.attributes.name ?? 'No product name set') }</p>
              </Link>
            </div>
          ),
        },
        {
          Header: 'ID',
          id: 'id',
          accessor: 'id',
          filterLabel: 'ID',
          width: 40,
        },
        {
          Header: 'Client',
          id: 'client',
          Filter: DropDownFilter,
          filterLabel: 'Client',
          width: 100,
          accessor: (data: any) => data?.clientContact?.address?.company,
          Cell: (data: any) => <p>{data?.value || '-'}</p>,
        },
        {
          Header: 'Type',
          id: 'type',
          accessor: (data: IEventResponse) => data.bundleVariant?.type,
          Filter: DropDownFilter,
          filterLabel: 'Type',
          filter: 'exact',
          width: 60,
        },
        {
          Header: 'Status',
          id: 'status',
          accessor: 'status',
          Filter: DropDownFilter,
          filterLabel: 'Status',
          width: 80,
          Cell: (data: any) => (
            <p className={styles.customCell} style={{ color: data.value === EVENT.STATUS.CANCELLED ? 'red' : 'inherit' }}>
              {data.value}
            </p>
          ),
        },
        {
          Header: 'Deal ID',
          id: 'dealId',
          accessor: 'pipedrive.dealId',
          filterLabel: 'Deal ID',
          width: 60,
          Cell: (data: any) => <p>{data?.value || '-'}</p>,
        },
        {
          Header: 'Date',
          id: 'date',
          accessor: (data: any) => data.eventDates.map(({ dateStart }: any) => new Date(dateStart)),
          Filter: DateRangeFilter,
          filter: 'DateRangeBetween',
          filterLabel: 'Date',
          filterRowSpan: 2,
          width: 140,
          Cell: (data: any) => {
            const [showMore, setShowMore] = useState(false);
            const moreThenTwo = data.row.original.eventDates.length > 2;
            const RenderDates = () => {
              const eventDates = moreThenTwo && !showMore ? data.row.original.eventDates.slice(0, 2) : data.row.original.eventDates;

              return eventDates.map(({ dateStart, dateEnd, trainerMapper }: EventDate, i: number) => {
                const day = i + 1;
                const { trainer } = trainerMapper?.find(tr => tr.role.toLowerCase() === 'lead') || {};

                return (
                  <span key={`${Date.now()}${day}`}>
                    <b>
                      {`Day ${day}`} {moreThenTwo && `(${trainer?.user?.contact.firstName} ${trainer?.user?.contact.lastName})`}
                    </b>
                    <p>
                      {new Intl.DateTimeFormat('de-DE', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      }).format(new Date(dateStart))}
                      {' - '}
                      {new Intl.DateTimeFormat('de-DE', {
                        hour: 'numeric',
                        minute: 'numeric',
                      }).format(new Date(dateEnd))}
                    </p>
                  </span>
                );
              });
            };

            return (
              <>
                <RenderDates />
                {moreThenTwo && (
                  <Button
                    type='button'
                    color='white'
                    spacingTop='xxs'
                    size='sm'
                    onClick={() => setShowMore(!showMore)}
                    className={styles.showMore}
                  >
                    {showMore ? (
                      <>
                        show less dates
                        <Icon src={SortIcon} className={styles.arrowUp}/>
                      </>
                    ) : (
                      <>
                        show all dates
                        <Icon src={SortIcon} />
                      </>
                    )}
                  </Button>
                )}
              </>
            );
          },
        },
        {
          Header: 'Seats',
          id: 'seats',
          width: 40,
          accessor: (data: any) => data,
          Cell: (data: any) => (
            <div className={styles.mainCell}>
              <a href={`${url}/${data.row.original.id}/participants`}>
                {`${data.row.original.stock}/${data.row.original.stockTotal}`}
              </a>
            </div>
          ),
        },
        {
          Header: 'Location',
          id: 'location',
          accessor: (data: any) => data.eventLocation?.name || data.eventLocation?.contact?.address.city || data.clientContact?.address?.city || '-',
          Filter: DropDownFilter,
          filterLabel: 'Location',
          width: 120,
        },
        {
          Header: 'Organization',
          id: 'organization',
          accessor: (data: IEvent) => data.bundleVariant?.bundleVersion?.enabledForOrganisations.reduce((acc, _id) => {
            const found = organisations?.find(({ id }) => id === _id);
            if (found) {
              acc.push(found.name);
            }
            return acc;
          }, [] as string[]),
          Cell: (data: { value: string[] }) => (
            <ul>
              {data.value &&
                data.value.map(value => (<li>{value}</li>))}
            </ul>
          ),
          Filter: DropDownFilter,
          filterLabel: 'Organization',
          width: 100,
        },
      ];
    };
