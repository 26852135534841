import { Dispatch } from 'redux';
import { gql } from '@brainbirds/shared-core';
import { gql as apolloGql } from '@apollo/client';
import { GET_ORGANISATIONS } from '~/store/reducers/organisations';
import client from '~/services/apolloClient';


import { ThunkResult } from '~/store/interfaces';

export const getOrganisations = (): ThunkResult<Promise<void>> => async (dispatch: Dispatch) => {
  try {
    const organisations = gql.generateQuery({
      organisations: ['locale', 'primaryLocation', 'parentOrganisation'],
    });

    const { data } = await client.query({
      query: apolloGql`${
        organisations.toString()
      }`
    });

    const payload = data.organisations;

    dispatch({ type: GET_ORGANISATIONS, payload });

    return payload;
  } catch (err: any) {
    throw new Error(err.response.data.message);
  }
};
