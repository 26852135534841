import { initialState } from '~/store/reducers/initialState';
import { UPDATE_PARTICIPANTS_LIST, UPDATE_PARTICIPANTS_LIST_WITH_UPDATED_PARTICIPANT } from '~/constants/action-types';
import IParticipantResponse from '~/interfaces/participants';

interface IAction {
  payload: IParticipantResponse[];
  type: string;
}

export const participantsReducer = (state: any = initialState.participants, action: IAction): any => {
  switch (action.type) {
    case UPDATE_PARTICIPANTS_LIST:
      return { ...state, participantsList: action.payload };
    case UPDATE_PARTICIPANTS_LIST_WITH_UPDATED_PARTICIPANT:
      return { ...state, participantsList: [...state.participantsList].map((item: any) => {

        if (item.id !== action.payload[0].id) {
          return item;
        }

        return {...action.payload[0]};
      })
      };

    default:
      return state;
  }
};
