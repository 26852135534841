import { Event, events as eventsHelper, Trainer } from '@brainbirds/shared-core';
import { Dispatch } from 'redux';
import { generateQuery } from '@brainbirds/shared-core/dist/gql';
import { gql } from '@apollo/client';
import { UPDATE_EVENT_LIST, UPDATE_SINGLE_EVENT } from '~/constants/action-types';
import apolloClient from '~/services/apolloClient';

import { ThunkResult } from '~/store/interfaces';

export const getEventsFromTrainer = (): ThunkResult<Promise<void>> => async (dispatch: Dispatch) => {
  try {
    // const query = generateQuery('trainerSelf').toString();
    const query = generateQuery({
      trainerSelf: [{
        fragment: 'events',
        columns: ['id', 'displayAs', 'status', 'stock', 'stockTotal'],
        includes: [
          {
            fragment: 'eventDates',
            columns: ['dateStart', 'dateEnd'],
            includes: [{
              fragment: 'eventDateTrainerMapper',
              columns: ['role'],
              includes: [{
                fragment: 'trainer',
                columns: [],
                includes: [{
                  fragment: 'user',
                  columns: [],
                  includes: [{
                    fragment: 'contact',
                    columns: ['firstName', 'lastName'],
                  }],
                }],
              }],
            }],
          },
          {
            fragment: 'bundleVariant',
            columns: ['type'],
            includes: [{
              columns: ['attributes', 'enabledForOrganisations'],
              fragment: 'bundleVersion',
            }],
          },
          {
            fragment: 'clientContact',
            columns: [],
            includes: [{
              fragment: 'address',
              columns: ['company'],
            }],
          },
          {
            fragment: 'eventLocation',
            columns: ['name'],
            includes: [{
              fragment: 'contact',
              columns: [],
              includes: [{
                fragment: 'address',
                columns: ['city'],
              }],
            }],
          },
          'pipedrive',
        ],
      }],
      columns: [],
    }).toString();
    const { data } = await apolloClient.query<{ trainerSelf: Trainer & { events: Event[]} }>({
      query: gql`${query}`,
      fetchPolicy: 'network-only'
    });
    const now = Date.now();

    const events = eventsHelper.splitFutureAndPast(data.trainerSelf.events);
    eventsHelper.sortByEventDates(events.gthEqualTimestamp, { timestamp: now, orderBy: 'asc' });
    eventsHelper.sortByEventDates(events.lthTimestamp, { byMaxDate: true, orderBy: 'desc' });

    dispatch({ type: UPDATE_EVENT_LIST, payload: [ ...events.gthEqualTimestamp, ...events.lthTimestamp ] });
  } catch (err: any) {
    throw new Error(err.message);
  }
};

export const getSingleEventFromTrainer = (eventId: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch) => {
  try {
    const query = generateQuery({
      event: [
        'pipedrive',
        {
          fragment: 'bundleVariant',
          columns: ['type'],
          includes: [{
            columns: ['attributes', 'enabledForOrganisations'],
            fragment: 'bundleVersion',
          }],
        },
        {
          fragment: 'clientContact',
          columns: [],
          includes: [{
            fragment: 'address',
            columns: ['company'],
          }],
        },
        {
          fragment: 'eventLocation',
          columns: ['name'],
          includes: [{
            fragment: 'contact',
            columns: [],
            includes: [{
              fragment: 'address',
              columns: ['city'],
            }],
          }],
        },
        {
          fragment: 'eventDates',
          columns: ['dateStart', 'dateEnd', 'feedbackLink'],
          includes: [
            {
              fragment: 'webinar',
              columns: ['sessionData'],
            },
            {
              fragment: 'eventDateTrainerMapper',
              columns: ['role'],
              includes: [{
                fragment: 'trainer',
                columns: [],
                includes: [{
                  fragment: 'user',
                  columns: [],
                  includes: [{
                    fragment: 'contact',
                    columns: ['firstName', 'lastName'],
                  }],
                }],
              }]
            },
          ],
        },
        {
          fragment: 'trainerContact',
          columns: ['firstName', 'lastName', 'email', 'telephone'],
          includes: [{
            fragment: 'address',
            columns: ['street', 'houseNumber', 'postalCode', 'city', 'additionalInfo', 'company'],
            includes: [{
              fragment: 'country',
              columns: ['name'],
            }],
          }],
        },
      ],
      columns: ['id', 'trainerComment', 'displayAs', 'status', 'language'],
    }).toString();
    const { data } = await apolloClient.query<{ event: Event[] }>({
      query: gql`${query}`,
      variables: { id: Number(eventId) },
      fetchPolicy: 'network-only'
    });
    dispatch({ type: UPDATE_SINGLE_EVENT, payload: data.event });
  } catch (err: any) {
    throw new Error(err.message);
  }
};
