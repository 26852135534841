export const initialState = {
  user: {
    accountData: {},
    resetPassword: {
      error: undefined,
      success: undefined,
    },
  },
  events: {
    eventList: [],
    singleEvent: {},
  },
  participants: {
    participantsList: [],
  },
  organisations: [],
};
