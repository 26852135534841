import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ATTENDANCE } from '@brainbirds/shared-core/dist/constants/participant';
import { RootState } from '~/store';
import { updateParticipantsAttendance } from '~/store/actions/participantsActions';

import styles from './AttendanceCheckbox.module.scss';

interface IPropTypes {
  id: number;
  attendance: string;
}

const AttendanceCheckbox: React.FC<IPropTypes> = ({id, attendance}) => {
  const { id: eventId } = useSelector((state: RootState) => state.events.singleEvent);
  const dispatch = useDispatch();

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const {checked} = (event.target as HTMLInputElement);
    dispatch(updateParticipantsAttendance(eventId, id, checked ? ATTENDANCE.CONFIRMED: ATTENDANCE.UNCONFIRMED));
  };

  return (
    <div className={styles.attendanceCheckbox}>
      <label htmlFor={`attendance-${id}`}>
        <input
          type='checkbox'
          id={`attendance-${id}`}
          checked={attendance === ATTENDANCE.CONFIRMED}
          onChange={handleChange}
        />
        {attendance}
      </label>
    </div>
  );};

export default AttendanceCheckbox;
