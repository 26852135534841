import { initialState } from '~/store/reducers/initialState';
import { UPDATE_CURRENT_USER, USER_LOGOUT, CUSTOMER_NEW_PW_STATUS } from '~/constants/action-types';

interface IAction {
  payload?: any;
  type: string;
  success: boolean;
  error: string;
}

export const userReducer = (state: any = initialState.user, action: IAction): any => {
  switch (action.type) {
    case UPDATE_CURRENT_USER:
      return { ...state, accountData: action.payload };

    case USER_LOGOUT:
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      return initialState.user;

    case CUSTOMER_NEW_PW_STATUS: {
      return {
        ...state,
        resetPassword: {
          success: action.success !== undefined ? action.success : initialState.user.resetPassword.success,
          error: action.error || initialState.user.resetPassword.error,
        },
      };
    }

    default:
      return state;
  }
};
