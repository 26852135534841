import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { HttpsRedirect } from '@brainbirds/frontend-library';
import Dashboard from '~/pages/Dashboard';
import Events from '~/pages/Events';
import NotFound from '~/pages/NotFound';
import Login from '~/pages/Auth';
import { SetNewPassword } from '~/components/Auth';
import PrivateRoute from '~/components/PrivateRoute';
import { useDispatch } from 'react-redux';
import { getOrganisations } from './store/actions/organisations';

const App: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrganisations());
  }, []);
  return (
    <HttpsRedirect>
      <Switch>
        <Route path='/login' component={Login} />
        <Route exact path='/setpw' component={SetNewPassword} />
        <PrivateRoute exact path={['/', '/dashboard']} component={Dashboard} title='Dashboard' />
        <PrivateRoute path='/events' component={Events} title='Events' />
        <PrivateRoute exact path='*' component={NotFound} title='Page Not Found' />
      </Switch>
    </HttpsRedirect>
  );
};

export default App;
