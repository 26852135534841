import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { Button, Spinner } from '@brainbirds/frontend-library';
import { getSingleEventFromTrainer } from '~/store/actions/eventActions';
import { RootState } from '~/store';
import { ReduxDispatch } from '~/store/interfaces';
import { EventDates, EventDesc, TrainerContact } from './components';

import styles from './Details.module.scss';

const EventDetails: React.FC = () => {
  const dispatch = useDispatch<ReduxDispatch>();
  const { slug } = useParams<{ slug: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getSingleEventFromTrainer(slug)).then(() => setIsLoading(false));
  }, [dispatch]);

  const event = useSelector((state: RootState) => state.events.singleEvent);
  const eventDates = event?.eventDates;
  const trainerContact = event?.trainerContact;

  const calendarEventDesc = {
    subject: event.displayAs,
    location: event.location?.name,
  };

  return (
    <div className={styles.eventDetailsWrapper}>
      <h1>
        <Link to='/events'>Events</Link>
        &nbsp;/ ID: {slug}
      </h1>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className={styles.content}>
          <h3>Event Details</h3>
          <EventDesc event={event} />
          <h3>Dates & Dial in</h3>
          {eventDates && <EventDates evtDates={eventDates} calendarEventDesc={calendarEventDesc} />}
          {trainerContact && (
            <>
              <h3>Contact Data</h3>
              <TrainerContact trainerContact={trainerContact} />
            </>
          )}
          <h3>Comment</h3>
          <p>{event?.trainerComment || '-'}</p>
        </div>
      )}
      <Button color='pink' variant='outlined' spacingTop='lg' href='/events' className={styles.buttonBack}>
        Back to table
      </Button>
    </div>
  );
};

export default EventDetails;
