import React from 'react';

import styles from './AuthLayout.module.scss';

interface IPropTypes {
  children: React.ReactElement;
}

const AuthLayout: React.FC<IPropTypes> = ({ children }) => (
  <>
    <div className={styles.background}>
      <img src={`${process.env.REACT_APP_CDN_URL}/public/loginbg-studio.jpg`} alt='background'/>
    </div>
    <div className={styles.formContainer} id='authLayout'>{children}</div>
  </>
);

export default AuthLayout;
