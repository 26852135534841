import React from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button } from '@brainbirds/frontend-library';
import { login } from '~/store/actions/userActions';
import { useAppDispatch } from '~/store';

import styles from '~/components/Auth/Auth.module.scss';

const LoginForm: React.FC = () => {
  const { push } = useHistory();
  const dispatch = useAppDispatch();

  return (
    <>
      <div className={styles.header}>
        <h1>Login</h1>
        <h4>Please input E-Mail and Password</h4>
      </div>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required('E-Mail is required'),
          password: Yup.string().required('Password is required'),
        })}
        onSubmit={async ({ email, password }, { setStatus, setSubmitting }) => {
          setStatus();
          try {
            dispatch(login({ email, password })).then(() => push('/dashboard'));
          } catch (error: any) {
            setSubmitting(false);
            setStatus(error.message);
          }
        }}
      >
        {({ status, isSubmitting }) => (
          <Form data-testid='login-form'>
            <div className={styles.inputWrap}>
              <label htmlFor='email'>E-Mail</label>
              <Field name='email' id='email' type='text' className={styles.inputFormControl} />
              <ErrorMessage name='email' component='div' className={styles.inputErr} />
            </div>
            <div className={styles.inputWrap}>
              <label htmlFor='password'>Password</label>
              <Field name='password' id='password' type='password' className={styles.inputFormControl} />
              <ErrorMessage name='password' component='div' className={styles.inputErr} />
            </div>
            <div className={styles.inputWrap}>
              <Button color='pink' type='submit' spacingTop='lg' disabled={isSubmitting}>
                  Login
              </Button>
              <Button color='pink' variant='outlined' type='button' spacingTop='md' href='/login/password-reset'>
                  Forgot Password?
              </Button>
            </div>
            {status && <div className='alert alertError'>{status}</div>}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LoginForm;
