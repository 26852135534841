import axios from 'axios';
import { isValid as accessTokenIsValid } from './token';

interface Tokens {
  refreshToken: string;
  accessToken: string;
}

let isLoading = false;
let resolverStack = [] as ((value: Tokens | PromiseLike<Tokens>) => void)[];

const _refresh = async (): Promise<Tokens> => {
  try {
    const currentRefreshToken = localStorage.getItem('refreshToken');
    isLoading = true;
    const { data: { refreshToken, accessToken }} = await axios
      .get<Tokens>(`${process.env.REACT_APP_API_ENDPOINT}/users/token`, {
      headers: {
        Authorization: `Bearer ${currentRefreshToken}`,
      },
    });

    isLoading = false;
    const tokens = { refreshToken, accessToken };

    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);

    while (resolverStack.length > 0) {
      const resolver = resolverStack.shift();
      if (resolver) {
        resolver(tokens);
      }
    }

    return tokens;
  } catch(error) {
    resolverStack = [];
    window.location.href = '/login';
    throw error;
  }
};

export const refresh = (): Promise<Tokens> => new Promise((resolve) => {
  // If the refresh token has already been fetched in the meantime, it is fetched from the local storage
  if (accessTokenIsValid()) {
    const accessToken = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');
    if (accessToken && refreshToken) {
      resolve({
        accessToken,
        refreshToken,
      });
    }
  }
  // refesh token already will be loading, queue the requests
  if (isLoading) {
    resolverStack.push(resolve);
  }
  // first request to get the refresh token
  else {
    _refresh().then((tokens) => resolve(tokens));
  }
});
