import axios from 'axios';

import { store } from '~/store';
import { logout } from '~/store/actions/userActions';

import { refresh } from './refeshToken';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken && config?.headers) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const config = error?.config;
    if (error.response.status === 401 && !config?.sent) {
      try {
        const { accessToken } = await refresh();

        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
        };

        return axiosInstance(config);
      } catch (suberror) {
        store.dispatch(logout());
        window.location.href = '/login';
      }

      return Promise.resolve();
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
