import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Table, Spinner } from '@brainbirds/frontend-library';
import isEmpty from 'lodash/isEmpty';
import { getParticipantsForEvent } from '~/store/actions/participantsActions';
import { getSingleEventFromTrainer } from '~/store/actions/eventActions';
import { generateColumnsFn } from '~/components/Events/Participants/generateData';
import { RootState } from '~/store';

import { ReduxDispatch } from '~/store/interfaces';

import styles from './ParticipantsList.module.scss';

const EventParticipants: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const dispatch = useDispatch<ReduxDispatch>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { participantsList } = useSelector((state: RootState) => state.participants);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getParticipantsForEvent(slug)).then(() => setIsLoading(false));
    dispatch(getSingleEventFromTrainer(slug));
  }, [dispatch]);

  return (
    <>
      <h1>
        <Link className={styles.headerLink} to='/events'>Events</Link>&nbsp;/ Participants / Event ID: {slug}
      </h1>
      {isLoading ? (
        <Spinner />
      ) : (!isEmpty(participantsList) ?
        <Table data={participantsList} columns={generateColumnsFn} tableKey='participants' />
        : <p className={styles.noParticipants}>No participant have been assigned to this event.</p>
      )}
    </>
  );
};

export default EventParticipants;
