import { Dispatch } from 'redux';
import {
  UPDATE_PARTICIPANTS_LIST,
  UPDATE_PARTICIPANTS_LIST_WITH_UPDATED_PARTICIPANT,
  MEDIA_FILE_GENERATED_SUCCESSFULLY,
  GENERATE_MEDIA_FILE
} from '~/constants/action-types';
import { Participant } from '@brainbirds/shared-core';
import axiosInstance from '~/services/httpService';

import { ThunkResult } from '~/store/interfaces';
import { store } from '..';

export const getParticipantsForEvent = (eventId: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch) => {
  try {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_ENDPOINT}/events/${eventId}/trainer/participants`
    );
    dispatch({ type: UPDATE_PARTICIPANTS_LIST, payload: res.data });
  } catch (err: any) {
    throw new Error(err.response.data.message);
  }
};

export const updateParticipantsAttendance = (eventId: number, participantId: number, attendance: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await axiosInstance.put(
        `${process.env.REACT_APP_API_ENDPOINT}/events/${eventId}/trainer/participants/${participantId}`,
        { attendance });
      const { participantsList = [] } = store.getState().participants || {};
      const participant = (participantsList as Participant[]).find(({ id }) => id === participantId);
      dispatch({ type: UPDATE_PARTICIPANTS_LIST_WITH_UPDATED_PARTICIPANT, payload: [{ ...participant, attendance }] });
    } catch (err: any) {
      throw new Error(err.response.data.message);
    }
  };

export const generateMediaFileForParticipant = (eventId: number, participantId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({ type: GENERATE_MEDIA_FILE });
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_API_ENDPOINT}/pdf/trainers-participant/${participantId}/certificate`);

      if (res.status === 200) {
        dispatch({ type: MEDIA_FILE_GENERATED_SUCCESSFULLY });

        const resp = await axiosInstance.get(
          `${process.env.REACT_APP_API_ENDPOINT}/events/${eventId}/trainer/participants/${participantId}`
        );

        dispatch({ type: UPDATE_PARTICIPANTS_LIST_WITH_UPDATED_PARTICIPANT, payload: [resp.data] });
      }
    } catch (err: any) {
      throw new Error(err.response.data.message);
    }
  };
