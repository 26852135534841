import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { regex as passwordRegex } from '@brainbirds/shared-core';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

import { Button } from '@brainbirds/frontend-library';
import { createNewPassword } from '~/store/actions/userActions';
import { RootState } from '~/store';
import AuthLayout from '~/components/Auth/AuthLayout';

import styles from '~/components/Auth/Auth.module.scss';

const SetNewPassword: React.FC = () => {
  const dispatch = useDispatch();
  const { success, error } = useSelector((state: RootState) => state.user.resetPassword);
  const urlParams = new URLSearchParams(window.location.search);
  const tempToken = urlParams.get('bbt');

  return (
    <AuthLayout>
      <>
        <div className={styles.header}>
          {success ? (
            <>
              <h1>Success!</h1>
              <p className='alert alertSuccess'>Password successfully updated</p>
              <Button color='pink' href='/login' spacingTop='md'>Back to Login</Button>
            </>
          ) : tempToken !== null && !success ? (
            <>
              <h1>Welcome!</h1>
              <h4>Please define your new password.</h4>
            </>
          ) : (
            <>
              <h1>Token is missing</h1>
              <h4>Please contact <a href='mailto:hello@brainbirds.de'>Support</a>.</h4>
            </>
          )}
        </div>
        {tempToken !== null && !success && (
          <div className={styles.formSection}>
            <Formik
              initialValues={{
                password: '',
                passwordConfirm: '',
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .required('Please Enter your password')
                  .matches(
                    passwordRegex,
                    'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
                  ),
                passwordConfirm: Yup.string()
                  .oneOf([Yup.ref('password'), null], 'Passwords must match')
                  .required('confirm password is required'),
              })}
              onSubmit={({ password }) => {
                dispatch(createNewPassword(password, tempToken));
              }}

              render={({ status, isSubmitting }) => (
                <Form>
                  <div className={styles.inputWrap}>
                    <label htmlFor='password'>new password</label>
                    <Field name='password' type='password' className={styles.inputFormControl} />
                    <ErrorMessage name='password' component='div' className={styles.inputErr} />
                  </div>
                  <div className={styles.inputWrap}>
                    <label htmlFor='passwordConfirm'>confirm password</label>
                    <Field name='passwordConfirm' type='password' className={styles.inputFormControl} />
                    <ErrorMessage name='passwordConfirm' component='div' className={styles.inputErr} />
                  </div>
                  <Button color='pink' type='submit' spacingTop='md' disabled={isSubmitting}>Save</Button>
                  {status && <div className='alert alert-danger'>{status}</div>}
                </Form>
              )}
            />
            <Button color='pink' variant='outlined' spacingTop='md' href='/login'>
              Back to Login
            </Button>
          </div>
        )}
        {error && (
          <div className='alert alertError' style={{ marginTop: '1rem' }}>
            <h4>Password could not be reset</h4>
            <p>This link is now invalid!</p>
            <p>Enter your E-Mail <Link
              to={{
                pathname: '/login',
                state: {
                  fromPwReset: true,
                },
              }}
              style={{ color: '#5f6f88' }}
            >here</Link> to get a new link.
            </p>
          </div>
        )}
      </>
    </AuthLayout>
  );
};

export default SetNewPassword;
