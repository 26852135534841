import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Spinner } from '@brainbirds/frontend-library';
import { generateMediaFileForParticipant } from '~/store/actions/participantsActions';
import { RootState, useAppDispatch } from '~/store';

interface IPropTypes {
  participantId: number;
}

const GenerateCertificateButton: React.FC<IPropTypes> = ({ participantId }) => {
  const { id: eventId } = useSelector((state: RootState) => state.events.singleEvent);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleOnClick = () => {
    setIsLoading(true);
    dispatch(generateMediaFileForParticipant(eventId, participantId)).finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Button type='button' color='pink' variant='outlined' spacingTop='xxs' size='sm' onClick={handleOnClick}>
          Create PDF
        </Button>
      )}
    </>
  );
};

export default GenerateCertificateButton;
