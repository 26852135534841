import React from 'react';
import { enums, makeUrls, sortStartDates, EventDate, EventDateTrainerMapper } from '@brainbirds/shared-core';
import { capitalize } from 'lodash';

import styles from '../Details.module.scss';

const customerSessionTypes = Object.values(enums.webinar.customerSessionType);

type IEventDate = Omit<EventDate, 'dateStart' | 'dateEnd' | 'trainers'> & {
  dateStart: string;
  dateEnd: string;
  trainers: {
    role: EventDateTrainerMapper['role'];
    firstName: string;
    lastName: string;
  }[];
};

interface IPropTypes {
  evtDates: IEventDate[];
  calendarEventDesc: {
    subject: string;
    location: string;
  };
}

const EventDates: React.FC<IPropTypes> = ({ evtDates, calendarEventDesc }) => {
  const cloneEvtDates = structuredClone(evtDates);
  sortStartDates(cloneEvtDates);

  return (
    <>
      {
        cloneEvtDates.map((evtDate, i) => {
          const day = i + 1;
          const sessionData = evtDate.webinar?.sessionData;
          const { deepLink = '', sessionId = '', password = '' } = sessionData || {};
          const beautifiedSessionTypeName = capitalize(sessionData?.type).replace(/([A-Z])/g, ' $1');
          const calendarEvent = {
            ...calendarEventDesc,
            description: `Online Tool: ${beautifiedSessionTypeName || ''}
              Telefonkonferenz / Meeting ID: ${sessionId}
              Webinar Link: ${deepLink}
              Password: ${password}`,
            dateStart: evtDate.dateStart,
            dateEnd: evtDate.dateEnd,
          };

          const calendarUrls = makeUrls(calendarEvent);

          const [ trainer ] = (evtDate.trainerMapper || []).filter(({ role }) => role.toLowerCase() === enums.trainer.role.LEAD);
          const { firstName, lastName } = trainer.trainer?.user?.contact || {};

          return (
            <span
              className={styles.contentParagraph}
              key={evtDate.dateStart + day}
            >
              <p>{`Day ${day}:`}</p>
              <strong>
                {new Intl.DateTimeFormat('de-DE', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                }).format(new Date(evtDate.dateStart))}
                {' - '}
                {new Intl.DateTimeFormat('de-DE', {
                  hour: 'numeric',
                  minute: 'numeric',
                }).format(new Date(evtDate.dateEnd))}
                {' '}
                <a href={calendarUrls.ics}>Download iCal</a>
              </strong>

              <p>Trainer:</p>
              <strong>{firstName} {lastName}</strong>

              <p>Online Tool:</p>
              <strong>
                {beautifiedSessionTypeName || '-'}
                {
                  customerSessionTypes.find(
                    sessionType => sessionType === sessionData?.type
                  ) && <span>(Einwahldaten kommen vom Kunden)</span>
                }
              </strong>

              <p>Telefonkonferenz / Meeting ID:</p>
              <strong>{sessionId || '-'}</strong>

              <p>Webinar Link:</p>
              {deepLink ? (
                <a href={deepLink} target='_blank' rel='noreferrer'>
                  {deepLink}
                </a>
              ) : (
                <strong>-</strong>
              )}

              <p>Password:</p>
              <strong>{password || '-'}</strong>

              <p>Feedback Link:</p>
              <p>{
                evtDate.feedbackLink
                  ? <a href={evtDate.feedbackLink} target='_blank' rel='noreferrer'>{evtDate.feedbackLink}</a>
                  : '-'
              }</p>
            </span>
          );
        })
      }
    </>
  );
};

export default EventDates;
