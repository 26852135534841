import React from 'react';
import ReactDOM from 'react-dom/client';
import jwtDecode from 'jwt-decode';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import axiosInstance from './services/httpService';

interface Token {
  exp: number;
  id: number;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const handleRefreshToken = () => {
  if (!document.hidden) {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const decodedToken = jwtDecode<Token>(accessToken);
      const sessionIsValid = decodedToken.exp * 1000 - Date.now() >= 0;
      if (!sessionIsValid) {
        axiosInstance.get(`${process.env.REACT_APP_API_ENDPOINT}/users/token/validate`);
      }
    }
  }
};
// refresh token when browser tab is active to force logout when idle time is over the ttl of jwt
window.addEventListener('focus', handleRefreshToken);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
