import React from 'react';
import Layout from '~/components/Layout';

const NotFound: React.FC = () => (
  <Layout>
    <div>
      <h1>Page Not Found</h1>
    </div>
  </Layout>
);

export default NotFound;
