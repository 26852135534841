import { ApolloClient, InMemoryCache, HttpLink, from, ApolloLink } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from '@apollo/client/link/retry';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_GRAPHQL,
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  },
}));

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  let errorMessage;
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      errorMessage = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
      // eslint-disable-next-line no-console
      console.error(errorMessage);
    });
  }
  if (networkError) {
    // if ('statusCode' in networkError && networkError.statusCode === 401) {
    //   axios.post(`${process.env.REACT_APP_API_ENDPOINT}/users/auth/token/refresh`, {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${localStorage.getItem('refreshToken')}`,
    //   }).then(({ data }) => {
    //     debugger;
    //     localStorage.setItem('token', data.accessToken);
    //   }).catch(() => {

    //   });
    // }
    errorMessage = `[Network error]: ${networkError}`;
    // eslint-disable-next-line no-console
    console.error(errorMessage);
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Number.POSITIVE_INFINITY,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error || error.response?.status === 401,
  },
});

const cleanTypeName = new ApolloLink((operation, forward) => {
  const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);
  if (operation.variables) {
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  return forward(operation).map((data) => data);
});

const client = new ApolloClient({
  link: from([cleanTypeName, retryLink, authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== 'production',
});

export default client;
