import React from 'react';
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import AuthLayout from '~/components/Auth/AuthLayout';
import { LoginForm, PasswordReset } from '~/components/Auth';

const Login: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <AuthLayout>
      <Switch>
        <Route exact path={path}>
          <LoginForm />
        </Route>
        <Route exact path={`${path}/password-reset`}>
          <PasswordReset />
        </Route>
      </Switch>
    </AuthLayout>
  );
};

export default Login;
